.title-main {
  &--01 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: normal;
    @include mq(sp) {
      @include fz_vw(20);
    }
  }
  &--02 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(20);
    }
  }
  &--03 {
    margin-bottom: 20px;
    font-size: 36px;
    @include mq(sp) {
      @include fz_vw(20);
    }
  }
}

.title-sub {
  &__wrap {
    margin-top: 50px;
    border-bottom: 3px solid $main;
    @include mq(sp) {
      margin-top: 0;
    }
  }
}

.title-simple {
  &--01 {
    margin-bottom: 20px;
    font-size: 3rem;
    @include mq(sp) {
      @include fz_vw(18);
      margin-bottom: 15px;
    }
  }
  &--02 {
    margin-top: 40px;
    margin-bottom: 20px;
    color: $main;
    font-size: 2.4rem;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(18);
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  &--03 {
    margin-top: 20px;
    margin-bottom: 0;
    color: $main;
    font-size: 2.0rem;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(16);
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

.table-title {
  &--01 {
    padding: 4px;
    background: $black;
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
}
