.search-box {
  position: absolute;
  top: 20px;
  left: 400px;
  @include mq(sp) {
    display: none;
    position: static;
    margin-top: 30px;
    text-align: center;
  }
  input[type=text] {
    width: 220px;
    padding: 6px 12px;
    border: 1px solid $main;
    border-radius: 20px;
    font-size: 1.4rem;
    @include mq(sp) {
      @include fz_vw(17);
      width: 300px;
      transform: translate3d(10px, 0, 0);
    }
  }
  input[type=image] {
    transform: translate3d(-36px, 0, 0);
  }
}

.gnavi {
  .search-box {
    display: none;
    @include mq(sp) {
      display: block;
    }
  }
}