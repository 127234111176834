.header__menu {
  display: none;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 44px;
  @include mq(sp) {
    display: block;
  }
  b {
    display: inline-block;
    padding-top: 28px;
    color: #fff;
    font-size: 10px;
  }
}
#panel-btn {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  background: $main;
  text-decoration: none;
  text-align: center;
  @include box-sizing(border-box);
}
#panel-btn .close {
  background: transparent;
}
#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
}
#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
  margin-top: -2px;
}
#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
  margin-top: -2px;
}
#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 3px;
  margin: -5px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}
#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 3px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}
#panel-btn-icon::before {
  margin-top: -12px;
}
#panel-btn-icon::after {
  margin-top: 8px;
}
#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: $main;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  @include fz_vw(20);
  @include mq(sp) {
    display: block;
  }
}