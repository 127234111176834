@charset "utf-8";

$w1200:1200px;
$w320:320px;
$largeRange: 960px;
$mediumRange: 959px;
$smallRange: 749px;
@mixin mq($media) {
	@if $media == sp {
		@media screen and (max-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == tab {
		@media screen and (max-width: #{$mediumRange}) and (min-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == no-sp {
		@media screen and (min-width: #{$smallRange}) {
			@content;
		}
	}
	@if $media == pc {
		@media screen and (min-width: #{$largeRange}) {
			@content;
		}
	}
	@if $media == w1200 {
		@media screen and (max-width: #{$w1200}) {
			@content;
		}
	}
	@if $media == w320 {
		@media screen and (max-width: #{$w320}) {
			@content;
		}
	}
	@if $media == wide {
		@media screen and (min-width: #{$w1200}) {
			@content;
		}
	}
}
