.pagetop {
  position: fixed;
  bottom: 180px;
  left: 50%;
  margin-left: 510px;
  cursor: pointer;
  z-index: 10000;
  @include mq(sp) {
    position: fixed;
    bottom: 20px;
    right: 10px;
    left: auto;
    margin-left: 0;
  }
  img {
    max-width: none;
  }
}


.footer {
  @include base-wrap--space();
  @include flex();
  position: relative;
  padding: 50px 0;
  color: $black;
  font-size: 1.4rem;
  &__wrap {
    background: $light-gray;
  }
  &__info {
    p {
      font-size: 1.3rem;
      line-height: 1.5;
    }
  }
  &__right {
  }
  &__cart {
    margin-bottom: 20px;
    @include mq(sp) {
      text-align: center;
    }
    a {
      display: inline-block;
      padding: 8px 18px 8px 50px;
      border: 2px solid $sub;
      background: url(/theme/wayouen-media/img/common/icon/icn_cart.svg) -8px 6px no-repeat;
      background-size: 30px 30px;
      color: $main;
      overflow: hidden;
      @include mq(sp) {
        background-position: 10px 6px;
        background-size: 24px 24px;
      }
      &:hover {
        @include mq(pc) {
          background-position: 8px 6px;
        }
      }
    }
  }
}

.fnavi {
  display: flex;
  justify-content: space-between;
  width: 50%;
  @include mq(sp) {
    width: 100%;
    margin-bottom: 30px;
  }
  ul {
    width: 50%;
    li {
      margin-bottom: 5px;
      a {
        position: relative;
        display: inline-block;
        padding-left: 15px;
        color: $black;
        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          display: block;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $main;
        }
        &::after {
          display: block;
          content: "";
          width: 0;
          border-bottom: 1px solid $main;
          transition: .2s;
        }
        &:hover::after {
          @include mq(pc) {
            width: 100%;
          }
        }
      }
    }
  }
}

.sns-links {
  margin-bottom: 20px;
  ul {
    @include clearfix;
    @include mq(sp) {
      text-align: center;
    }
  }
  li {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    img {
      width: 30px;
      height: 30px;
    }
    a {
      display: inline-block;
      vertical-align: top;
      &:hover {
        @include mq(pc) {
          transform: translateY(-5px);
        }
      }
    }
  }
}

.copyright {
  margin: 20px 0;
  color: #888;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.3rem;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(11);
    line-height: 1.2;
  }
}