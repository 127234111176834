@charset "utf-8";

/* ヘッダー */
.header {
  @include base-wrap();
  position: relative;
  padding: 10px;
  &__logo {
    margin: 5px 0;
    @include mq(sp) {
      float: none;
    }
    span {
      margin-left: 15px;
      line-height: 43px;
      color: #666;
    }
  }
  &__cart {
    position: absolute;
    right: 0;
    top: 15px;
    @include mq(sp) {
      display: none;
    }
    a {
      display: inline-block;
      padding: 5px 18px 5px 50px;
      border: 2px solid $sub;
      background: url(/theme/wayouen-media/img/common/icon/icn_cart.svg) -8px 3px no-repeat;
      background-size: 30px 30px;
      color: $main;
      overflow: hidden;
      @include mq(sp) {
        background-position: 6px 3px;
        background-size: 24px 24px;
      }
      &:hover {
        @include mq(pc) {
          background-position: 8px 3px;
        }
      }
    }
  }
}



/* グローバルナビゲーション */
.gnavi {
  @include base-wrap();
  position: relative;
  @include mq(sp) {
    padding: 10px 0 40px;
    background: $main;
  }
  &__wrap {
    background: $main;
    @include mq(sp) {
      position: absolute;
      top: 73px;
      left: 0;
      z-index: 10;
      display: none;
      width: 100%;
      height: 100vh;
      background: rgba(0,0,0,.7);
    }
  }
  & > ul {
    @include flex();
    @include mq(sp) {
      margin-bottom: 30px;
    }
    & > li {
      width: 20%;
      @include mq(sp) {
        width: auto;
      }
      a {
        display: block;
        color: #fff;
        font-size: 1.4rem;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        line-height: 50px;
        @include mq(sp) {
          @include fz_vw(17);
          width: auto;
          padding: 5px 30px;
        }
        &:hover {
          background: $sub;
          text-decoration: none;
        }
      }
      ul.dropdown-list {
        position: absolute;
        display: none;
        width: 20%;
        @include mq(sp) {
          position: static;
          display: block;
          width: 100%;
        }
        li {
          background: $black;
          a {
            display: block;
            padding: 5px 0;
            background: $black;
            color: #fff;
            text-align: center;
            line-height: 1.5;
            &:hover {
              color: $main;
            }
          }
        }
      }
    }
  }
  &__cart {
    display: none;
    @include mq(sp) {
      display: block;
      text-align: center;
    }
    a {
      display: inline-block;
      padding: 10px 18px 10px 50px;
      border: 2px solid #fff;
      background: url(/theme/wayouen-media/img/common/icon/icn_cart_w.svg) 10px 9px no-repeat;
      background-size: 24px 24px;
      color: #fff !important;
      font-size: 1.4rem;
      @include mq(sp) {
        @include fz_vw(17);
      }
    }
  }
}
.gnavi > ul > li:nth-child(1) a {
  padding: 12px 0;
  img {
    width: 24px;
    height: 26px;
    vertical-align: middle;
  }
}
.gnavi__wrap.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}
