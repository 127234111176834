.wrapper {
  overflow: hidden;
}

.main {
  @include clearfix;
  &--top {
  }
  &--page {
    margin-bottom: 60px;
  }
}

.base-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0;
  @include mq(sp) {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
  &--space {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
    @include mq(sp) {
      width: auto;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.screen-reader-text {
  display: none;
}

.page {
  @include clearfix;
  @include base-wrap--space;
  .contents {
    @include clearfix;
    float: left;
    width: 600px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
  }
  .side {
    float: right;
    width: 300px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
  }
}
.page--1col {
  @include clearfix;
  @include base-wrap--space;
  margin-bottom: 60px;
  .contents {
    @include clearfix;
  }
  .side {
  }
}

.social-links {
  margin-bottom: 50px;
}