@charset "utf-8";

.hero {
  @include flex;
  height: 380px;
  margin-bottom: 50px;
  overflow: hidden;
  @include mq(sp) {
    height: auto;
  }
  &__main {
    width: 70%;
    overflow: hidden;
    @include mq(sp) {
      width: auto;
    }
    img {
      width: 100%;
      height: 380px;
      object-fit: cover;
      @include mq(sp) {
        height: auto;
      }
    }
  }
  &__sub {
    width: 30%;
    @include mq(sp) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__item {
      overflow: hidden;
      img {
        width: 100%;
        height: 190px;
        object-fit: cover;
        @include mq(sp) {
          height: auto;
        }
      }
    }
  }
  &__mv {
    position: relative;
    &__meta {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      background: rgba(0,0,0,.6);
      color: #fff;
      @include mq(sp) {
      }
      .name {
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
        @include mq(sp) {
          display: none;
        }
      }
      .category {
        display: inline-block;
        padding: 2px 4px;
        border: 1px solid #fff;
        font-size: 1.2rem;
        line-height: 1;
        @include mq(sp) {
          display: none;
        }
      }
      .date {
        display: inline-block;
        font-size: 1.4rem;
        @include mq(sp) {
          display: none;
        }
      }
      .title {
        padding-top: 5px;
        line-height: 1.2;
        @include mq(sp) {
          @include fz_vw(14);
          padding-top: 0;
          border-color: $black;
        }
      }
    }
  }
}
.hero a {
  display: block;
  img {
    transition: 1s;
  }
  &:hover {
    img {
      @include mq(pc) {
        transform: scale(1.1,1.1);
        transition: 1s;
      }
    }
  }
}

.top-feed {
  @include base-wrap--space;
  @include flex;
  margin-bottom: 50px;
  &__item {
    width: 47%;
    margin-bottom: 60px;
    @include mq(sp) {
      width: 100%;
      margin-bottom: 40px;
    }
    ul {
      margin-bottom: 60px;
      li {
        margin-bottom: 30px;
        @include mq(sp) {
          margin-bottom: 25px;
        }
        a {
          display: flex;
          justify-content: space-between;
          color: $black;
        }
        .eyecatch {
          width: 30%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100px;
            object-fit: cover;
            transition: .2s;
            @include mq(sp) {
              height: 70px;
            }
          }
        }
        .data {
          width: 62%;
          @include mq(sp) {
            width: 64%;
          }
        }
        .category {
          margin-right: 10px;
          padding: 3px 6px 4px;
          background: $sub;
          color: #fff;
          font-size: 1.2rem;
          vertical-align: middle;
          @include mq(sp) {
            @include fz_vw(12);
            display: inline-block;
            height: 19px;
            padding: 0 6px;
            line-height: 19px;
            vertical-align: top;
          }
        }
        .title {
          margin: 10px 0;
          line-height: 1.6;
          @include mq(sp) {
            @include fz_vw(16);
            margin-top: 0;
            line-height: 1.5;
          }
        }
        .date {
          color: #777;
          vertical-align: middle;
          @include mq(sp) {
            display: inline-block;
            height: 19px;
            line-height: 19px;
            vertical-align: top;
          }
        }
      }
    }
  }
}
.top-feed a:hover img {
  @include mq(pc) {
    transform: scale(1.1,1.1);
    transition: .2s;
  }
}

.top-news {
  @include base-wrap--space;
  padding: 20px 0 50px;
  ul {
    @include flex;
    margin-bottom: 50px;
    li {
      width: 31%;
      @include mq(sp) {
        width: 100%;
        margin-bottom: 25px;
      }
      a {
        display: flex;
        justify-content: space-between;
        color: $black;
      }
      .eyecatch {
        width: 30%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
          transition: .2s;
          @include mq(sp) {
            height: 70px;
          }
        }
      }
      .data {
        width: 62%;
        @include mq(sp) {
          width: 64%;
        }
      }
      .title {
        margin-bottom: 10px;
        line-height: 1.2;
      }
      .text {
        line-height: 1.5;
      }
    }
  }
}
.top-news a:hover img {
  @include mq(pc) {
    transform: scale(1.1,1.1);
    transition: .2s;
  }
}

.top-products {
  padding: 50px 0 80px;
  background: $main;
  @include mq(sp) {
    padding: 30px 0 50px;
  }
  &__inner {
    @include base-wrap--space;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 24%;
      @include mq(sp) {
        width: 49%;
        margin-bottom: 5px;
      }
      p {
        margin-top: 10px;
        color: #fff;
        line-height: 1.5;
      }
    }
  }
}
