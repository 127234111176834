@charset "utf-8";

.breadcrumbs {
  @include base-wrap--space;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 1.4rem;
  @include mq(sp) {
    @include fz_vw(12);
  }
  &__list {
    @include clearfix();
    li {
      display: inline-block;
      &:not(:first-child) {
        &::before {
          padding-left: 6px;
          padding-right: 6px;
          content: "＞";
        }
      }
    }
  }
}
