@charset "utf-8";

.table {
  &--01 {
    @include mq(sp) {
      @include fz_vw(12);
    }
    &__title {
      background: #3E3A39;
      color: #fff;
    }
    &__subtitle {
      background: $light-gray;
      color: $black !important;
      text-align: center;
    }
    tr {
      border-bottom: 1px solid $gray;
    }
    tr:nth-child(2) {
      th {
        padding: 15px 0;
      }
    }
    th:not(:last-child) {
      border-right: 1px solid $gray;
    }
    td:not(:last-child) {
      border-right: 1px solid $gray;
    }
    th, td {
      width: 25%;
      padding: 7px 0;
      text-align: center;
    }
    td:nth-child(1) {
      color: $main;
    }
    th:nth-child(2) {
      background: $main;
      color: #fff;
    }
    td:nth-child(2) {
      background: $main;
      color: #fff;
    }
  }
  &--02 {
    border-bottom: 2px solid $gray;
    tr {
      th {
        padding: 4px;
        background: $main;
        color: #fff;
        font-size: 2.0rem;
        font-weight: normal;
        @include mq(sp) {
          @include fz_vw(16);
        }
      }
      td {
        padding: 6px;
        border-bottom: 1px solid $gray;
        background: $light-gray;
        color: $main;
        text-align: center;
        &:nth-child(1) {
          border-right: 1px solid $gray;
        }
      }
    }
  }
}

.table-sp {
  table {
    @include mq(sp) {
      display: table !important;
      width: 100% !important;
    }
    tbody {
      @include mq(sp) {
        display: table-row-group !important;
      }
    }
    tr {
      @include mq(sp) {
        display: table-row !important;
        width: 100% !important;
      }
      th {
        @include mq(sp) {
          display: table-cell !important;
        }
      }
      td {
        @include mq(sp) {
          display: table-cell !important;
        }
      }
    }
  }
  
}