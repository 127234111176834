@charset "utf-8";

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/

.side--1col {
  @include base-wrap();
  padding: 60px 0;
  @include mq(sp) {
    margin: 30px 20px;
  }
  .widget-area {
    @include flex(wrap);
    .widget {
      width: 30%;
      @include mq(sp) {
        width: auto;
      }
    }
  }
  .side-fb {
    margin: 0 auto;
    width: 500px;
    @include mq(sp) {
      width: auto;
    }
  }
  .fb-container {
    width: 100%;
  }
}


/* サイドバー */
.blog-calendar th,
.blog-calendar td {
  padding: 6px 0;
}
.blog-calendar tr:first-child td {
  background: $black;
  color: #fff;
}




/* サイドバナー */
.salon-banner {
  width: 1020px;
  margin: 30px auto;
  img {
    max-width: 100%;
  }
}

/* ローカルナビ */
.widget {
  margin-bottom: 50px;
}
.widget-local-navi > ul {
  margin: 0 -2px;
  padding: 0 2px 15px;
}
.widget-local-navi > ul > li {
    border-bottom: 1px dotted #CCCCCC;
}
.widget-local-navi li a {
    display: block;
    padding: 16px 10px 16px 16px;
    text-decoration: none;
    color: #333;
}
.widget-local-navi li a:hover {
  color: #CC3300;
}
.widget-local-navi li:last-child {
    border-bottom: none;
}
/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}
.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}
.blog-widget li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
}
.blog-widget li a {
  color: #1D4C86;
  text-decoration: none;
}
.blog-widget li a:hover {
  color: #00A0D9;
  text-decoration: underline;
}

.widget {
  margin-bottom: 30px;
}
.widget img {
  max-width: 100%;
}
.widget h2 {
  margin-bottom: 10px;
  padding: 10px 15px;
  background: $black;
  color: #fff;
  font-size: 15px;
}
.widget li {
  margin-left: 5px;
  margin-bottom: 10px;
}
.widget li a {
  position: relative;
  display: block;
  padding: 4px 0 4px 15px;
  color: #333333;
  text-decoration: none;
  &::before {
    position: absolute;
    top: 9px;
    left: 0;
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: $sub;
    border-radius: 50%;
  }
}

  /* ブログ side calendar */
.widget-blog-calendar table {
  border-collapse: collapse;
  box-sizing: border-box;
}
.widget-blog-calendar th {
  border: 1px solid #ededed;
}
.widget-blog-calendar td {
  position: relative;
  text-align: center;
  border: 1px solid #ededed;
}
.widget-blog-calendar td center {
  padding: 6px 10px;
  a {
    color: #fff;
  }
}
.widget-blog-calendar td > a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 6px 0;
  background: $main;
  color: #fff;
  text-align: center;
  text-decoration: underline;
}