@charset "utf-8";

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/

/* サブページ共通 */
.main--page {
  p {
    margin-bottom: 30px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  .section {
    margin-bottom: 60px;
    @include mq(sp) {
      margin-bottom: 30px;
    }
  }
  .copy {
    color: $main;
    font-size: 30px;
    font-weight: bold;
    @include mq(sp) {
      @include fz_vw(18);
    }
  }
}

.cms-area {
  h2 {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 10px;
    font-size: 2rem;
    font-weight: normal;
    line-height: 1.2;
    text-align: left;
    @include mq(sp) {
      margin-bottom: 15px;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #eee;
      border-left: 150px solid $black;
      box-sizing: border-box;
    }
    a {
      text-decoration: none;
    }
  }
  h3 {
    margin: 40px 0 20px;
    padding-bottom: 10px;
    font-size: 16px; font-size: 1.6rem;
    line-height: 1.2;
  }
  h4 {
    margin: 30px 0 15px;
    font-family: "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-weight: bold;
    font-size: 15px; font-size: 1.5rem;
    line-height: 1.2;
  }
  a {
    text-decoration: underline;
  }
  ul {
    & > li {
      padding-left: 25px;
      background: url(../img/common/icon/icon_list.png) 10px 9px no-repeat;
    }
  }
  ol {
    margin-left: 1em;
    & > li {
    }
  }
  table {
    width: 100% !important;
    border-collapse: collapse;
    border: 1px solid #ccc;
  }
  th {
    padding: 10px;
    background: #ededed;
    border-bottom: 1px dotted #ccc;
    text-align: center;
    vertical-align: middle;
  }
  td {
    padding: 10px;
    background: #fff;
    border-bottom: 1px dotted #ccc;
    text-align: left;
  }
}


