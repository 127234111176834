/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

.submit {
    margin: 60px 0;
    text-align: center;
    .button {
      display: inline-block;
      padding: 16px 80px;
      color: #fff;
      font-size: 1.8rem;
      font-weight: bold;
      font-family:"ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho",  serif;
      text-align: center;
      text-decoration: none;
      border-radius: 8px;
      -webkit-font-smoothing: antialiased;
      background: $main;
      background: -moz-linear-gradient(top,  $main 0%, $sub 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$main), color-stop(100%,$sub));
      background: -webkit-linear-gradient(top,  $main 0%,$sub 100%);
      background: -o-linear-gradient(top,  $main 0%,$sub 100%);
      background: -ms-linear-gradient(top,  $main 0%,$sub 100%);
      background: linear-gradient(to bottom,  $main 0%,$sub 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#125115', endColorstr='$sub',GradientType=0 );
      cursor: pointer;
      @include mq(sp) {
        width: 90%;
        margin-bottom: 10px;
        padding: 16px 0;
      }
    }
}
.auth-captcha {
    margin: 30px 0;
    text-align: center;
}
.form-table {
  @include mq(sp) {
    border: 0 !important;
  }
  tr {
    th {
      width: 300px;
      padding-left: 30px;
      text-align: left;
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
    td {
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 10px 0 30px;
      }
    }
  }
  input[type=text] {
    @include box-sizing(border-box);
    width: 90%;
    margin: 0 10px 4px 0;
    padding: 8px;
    border: 1px solid $main;
    font-size: 16px;
    @include mq(sp) {
      width: 100%;
    }
  }
  .checkbox {
    font-size: 16px;
  }
  textarea {
    @include box-sizing(border-box);
    width: 100%;
    padding: 8px;
    border: 1px solid $main;
    font-size: 16px;
    @include mq(sp) {
    }
  }
  select {
    @include box-sizing(border-box);
    position: relative;
    margin: 0 0 4px 0;
    border: 1px solid $main;
    font-size: 16px;
  }
  a {
    color: $main;
    text-decoration: underline;
  }
  ul {
    li {
      padding-left: 0;
      background: none !important;
      &::before {
        display: none;
      }
    }
  }
  .form-s {
    width: 20% !important;
    @include mq(sp) {
      width: 27% !important;
    }
  }
  .form-m {
    width: 36% !important;
    @include mq(sp) {
      width: 47% !important;
    }
  }
  .form-l {
    width: 90%;
    @include mq(sp) {
      width: 100%;
    }
  }
}
.required {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 5px;
  padding: 1px 4px;
  border-radius: 4px;
  background: #c00;
  color: #fff;
  font-size: 12px;
}
.mail-description {
  margin-bottom: 10px;
}
.mail-before-attachment {
  margin-right: 10px;
}
.mail-after-attachment {
  margin-right: 10px;
}
#flashMessage {
  margin-bottom: 10px;
}
.alert-message {
  color: #c00;
}

form {
  ul {
    margin: 0 !important;
    li {
      padding-left: 0 !important;
      &::before {
        display: none !important;
      }
    }
  }
}