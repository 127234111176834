@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ax5cum');
  src:  url('../fonts/icomoon.eot?ax5cum#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ax5cum') format('truetype'),
    url('../fonts/icomoon.woff?ax5cum') format('woff'),
    url('../fonts/icomoon.svg?ax5cum#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line:before {
  content: "\e900";
}
.icon-hatebu:before {
  content: "\e901";
}

.social-links {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 20%;
      padding: 0 !important;
      background: none !important;
      .sns-btn {
        display: block;
        margin: 0 1px 8px;
        max-width: 240px;
        height: 48px;
        background: $black;
        color: #fff;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 48px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        overflow: hidden;
        b {
          @include mq(sp) {
            display: none;
          }
        }
        i {
          @include mq(sp) {
            font-size: 24px;
            line-height: 48px;
          }
        }
      }
      .s_twitter {
        background: #55acee;
      }
      .s_facebook {
        background: #3B5998;
      }
      .s_hatena {
        background: #008fde;
      }
      .s_pocket {
        background: #ef4056;
      }
      .s_line {
        background: #1dcd00;
      }
    }
  }
}