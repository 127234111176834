@charset "utf-8";

.txt-pop {
  position: relative;
  width: 80%;
  margin: 0 auto 30px;
  padding: 24px 0 30px;
  text-align: center;
  border: solid 2px #b5b5b6;
  border-radius: 10px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 6px 7px 0 #b5b5b6;
  box-shadow: 6px 7px 0 #b5b5b6;
  @include mq(sp) {
    padding: 18px 0 20px;
  }
  &::before, &::after {
    position: absolute;
    display: block;
    content: '';
    border: solid transparent;
  }
  &::before {
    z-index: 3;
    right: 45%;
    bottom: -37px;
    border-width: 20px 15px;
    border-top-color: #FFFFFF;
  }
  &::after {
    z-index: 2;
    right: 43.7%;
    bottom: -45px;
    border-width: 20px 15px;
    border-top-color: #b5b5b6;
  }
}

