.blog-description {
  margin-bottom: 30px;
}
.blog {
  &__main {
    float: left;
    width: 600px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
  }
  &__side {
    float: right;
    width: 300px;
    @include mq(sp) {
      float: none;
      width: auto;
    }
    h2 {
      margin-bottom: 10px;
      padding: 10px 15px;
      font-size: 15px;
      &::after {
        display: none;
      }
    }
    ul {
      li {
        padding-left: 0;
        background: none;
      }
    }
    &--1col {
      @include base-wrap();
      padding: 60px 0;
      @include mq(sp) {
        margin: 30px 20px;
      }
      .widget-area {
        @include flex(wrap);
        .widget {
          width: 30%;
          @include mq(sp) {
            width: auto;
          }
        }
      }
      .side-fb {
        margin: 0 auto;
        width: 500px;
        @include mq(sp) {
          width: auto;
        }
      }
      .fb-container {
        width: 100%;
      }
      h2 {
        margin-bottom: 10px;
        padding: 10px 15px;
        font-size: 15px;
        &::after {
          display: none;
        }
      }
      ul {
        li {
          padding-left: 0;
          background: none;
        }
      }
    }
  }
  .meta {
    margin-bottom: 20px;
  }
  .category {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    a {
      display: inline-block;
      padding: 1px 10px;
      background: $sub;
      color: #fff;
      font-size: 1.4rem;
      text-decoration: none;
      @include mq(sp) {
        @include fz_vw(14);
      }
    }
  }
  .date {
    color: #777;
    vertical-align: middle;
  }
  .tag {
    span {
      display: inline-block;
      padding: 1px 20px;
      border: 1px solid $sub;
      color: $sub;
      font-size: 1.4rem;
      vertical-align: middle;
      @include mq(sp) {
        @include fz_vw(14);
      }
    }
  }
}
.blog-default-index {
  .post{
    @include clearfix;
    margin-bottom: 80px;
    @include mq(sp) {
    }
    & > a {
      position: relative;
      display: block;
      text-decoration: none;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(88,0,0,0);
        transition: 1s;
      }
      &:hover::after {
        @include mq(pc) {
          background: rgba(88,0,0,0.1);
          transition: 1s;
        }
      }
    }
    .post-wrap {
      @include clearfix;
    }
    .eyecatch {
      float: left;
      width: 30%;
      overflow: hidden;
      @include mq(sp) {
        float: none;
        width: auto;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        object-fit: cover;
        transition: .2s;
        @include mq(sp) {
        }
      }
    }
    .data {
      float: right;
      width: 67%;
      @include mq(sp) {
        float: none;
        width: auto;
      }
      .tag {
        margin-top: 20px;
      }
    }
    .text {
      margin-bottom: 0;
    }
    .more {
      text-align: right;
    }
  }
}

.blog-default-single {
  .meta {
    margin-top: 20px;
  }
  .eyecatch {
    margin-bottom: 6px;
  }
  .tag {
    display: block;
    margin-top: 10px;
  }
}

.blog-grid-index {
  @include flex(wrap);
  justify-content: flex-start;
  .post{
    @include clearfix;
    width: 30%;
    margin-right: 5%;
    margin-bottom: 60px;
    @include mq(sp) {
      width: auto;
      margin-right: 0;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    & > a {
      position: relative;
      display: block;
      height: 100%;
      text-decoration: none;
      @include mq(sp) {
        height: auto;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(88,0,0,0);
        transition: 1s;
      }
      &:hover::after {
        @include mq(pc) {
          background: rgba(88,0,0,0.1);
          transition: 1s;
        }
      }
    }
    .post-wrap {
      @include clearfix;
    }
    .eyecatch {
      margin-bottom: 10px;
      overflow: hidden;
      @include mq(sp) {
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        transition: .2s;
        @include mq(sp) {
          height: 100px;
        }
      }
    }
    .data {
      .tag {
        margin-top: 20px;
        @include mq(sp) {
          margin-top: 20px;
        }
      }
    }
    .text {
      margin-bottom: 0;
    }
    .more {
      text-align: right;
    }
  }
}

.blog-grid-single {
  .eyecatch {
    float: left;
    width: 30%;
    @include mq(sp) {
      float: none;
      width: auto;
      margin-bottom: 15px;
    }
  }
  .data {
    float: right;
    width: 65%;
    @include mq(sp) {
      float: none;
      width: auto;
    }
    .tag {
      display: block;
      margin-top: 10px;
    }
  }
  .post {
    clear: both;
  }
}

.blog-mix-index {
  .post{
    @include clearfix;
    margin-bottom: 80px;
    @include mq(sp) {
    }
    & > a {
      position: relative;
      display: block;
      text-decoration: none;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(88,0,0,0);
        transition: 1s;
      }
      &:hover::after {
        @include mq(pc) {
          background: rgba(88,0,0,0.1);
          transition: 1s;
        }
      }
    }
    .post-wrap {
      @include clearfix;
    }
    .eyecatch {
      float: left;
      width: 30%;
      overflow: hidden;
      @include mq(sp) {
        float: none;
        width: auto;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        object-fit: cover;
        transition: .2s;
        @include mq(sp) {
        }
      }
    }
    .data {
      float: right;
      width: 67%;
      @include mq(sp) {
        float: none;
        width: auto;
      }
      .tag {
        margin-top: 20px;
      }
    }
    .text {
      margin-bottom: 0;
    }
    .more {
      text-align: right;
    }
  }
}

.blog-mix-single {
  .eyecatch {
    width: 800px;
    margin: 0 auto 6px;
    @include mq(sp) {
      width: auto;
    }
  }
  .meta {
    margin-top: 20px;
    .tag {
      margin-top: 10px;
    }
  }
}

.blog-2col-index {
  @include flex(wrap);
  .post{
    @include clearfix;
    width: 46.5%;
    margin-bottom: 80px;
    @include mq(sp) {
      width: auto;
    }
    .post-wrap {
      @include clearfix;
    }
    .eyecatch {
      float: left;
      width: 40%;
      overflow: hidden;
      @include mq(sp) {
        float: none;
        width: auto;
        margin-bottom: 20px;
      }
      img {
        width: 100%;
        object-fit: cover;
        transition: .2s;
        @include mq(sp) {
        }
      }
    }
    .data {
      float: right;
      width: 56%;
      @include mq(sp) {
        float: none;
        width: auto;
      }
      .tag {
        margin-top: 20px;
      }
    }
    .text {
      margin-bottom: 0;
    }
    .more {
      text-align: right;
    }
  }
}

.blog-2col-single {
  .meta {
    margin-top: 20px;
  }
  .eyecatch {
    width: 800px;
    margin: 0 auto 6px;
    @include mq(sp) {
      width: auto;
    }
  }
  .tag {
    display: block;
    margin-top: 10px;
  }
}
