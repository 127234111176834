.button {
  &--01 {
    text-align: center;
    a {
      position: relative;
      display: inline-block;
      padding: 6px 50px;
      background: $sub;
      color: #fff !important;
      overflow: hidden;
      text-align: center;
      @include mq(sp) {
        width: 100%;
        padding: 10px 0;
      }
      span {
        z-index: 20;
      }
      &::after {
        position: absolute;
        right: 12px;
        top: 50%;
        content: "";
        display: block;
        width: 0;
        height: 0;
        margin-top: -6px;
        border-style: solid;
        border-width: 5px 0 5px 7px;
        border-color: transparent transparent transparent #fff;
      }
      &::before {
        position: absolute;
        top: -50px;
        left: -75px;
        content: "";
        display: block;
        width: 50px;
        height: 155px;
        background: #fff;
        opacity: .2;
        transform: rotate(35deg);
        transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 10;
      }
      &:hover {
        &::before {
          @include mq(pc) {
            left: 120%;
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          }
        }
      }
    }
  }
}

.button-2col {
  @include flex;
}

.shiny {
  overflow: hidden;
  &::before {
    display: block;
    position: absolute;
    z-index: -1;
    left: -30%;
    top: -50%;
    content: "";
    width: 80px;
    height: 200px;
    transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    background-image: linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
    background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,1)),color-stop(100%,rgba(255,255,255,0)));
    background-image: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, #ffffff rgba(255,255,255,1),rgba(255,255,255,0) 100%);
    animation: shiny 10s infinite linear;
    -webkit-animation: shiny 10s infinite linear;
    -moz-animation: shiny 10s infinite linear;
    opacity: .4;
  }
}

@keyframes shiny {
  0% {
    left: -30%;
  }
  5% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}
@-webkit-keyframes shiny {
  0% {
    left: -30%;
  }
  5% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}
@-moz-keyframes shiny {
  0% {
    left: -30%;
  }
  5% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}
