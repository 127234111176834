@charset "utf-8";

/* ブログ　pagination */
.pagination {
  margin: 40px 0 80px;
  font-family: $font-yu;
  text-align: center;
}
.pagination span.current,
.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a,
.pagination span.number a {
  margin: 0 2px;
  padding: 10px 16px;
  border: 1px solid #ddd;
  text-decoration: none !important;
  @include mq(sp) {
    margin: 0;
    font-size: 15px;
    line-height: 11px;
  }
}
.pagination span:first-child a {
  border-left: 1px solid #ddd;
}
.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a {
  padding: 10px;
}
.pagination span.current,
.pagination span a:hover {
  background: $main;
  color: #fff;
}
.pagination a:active {
  background: $main;
}
.pagination span.first a,
.pagination span.last a {
  position: relative;
}
.pagination span.first a::before,
.pagination span.last a::after {
  position: absolute;
  top: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 14px;
  margin-top: -6px;
  background: #000;
  @include mq(sp) {
    height: 11px;
    margin-top: -5px;
  }
}
.pagination span.first a::before {
  left: 13px;
  @include mq(sp) {
    left: 12px;
  }
}
.pagination span.last a::after {
  right: 13px;
  @include mq(sp) {
    right: 12px;
  }
}

/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/

.contents-navi {
  margin-top: 40px;
  margin-bottom: 120px;
  text-align: center;
  @include mq(sp) {
    margin-top: 20px;
    margin-bottom: 80px;
  }
  a {
    display: inline-block;
    width: 30%;
    border: 1px solid #ddd;
    line-height: 50px;
    text-align: center;
    text-decoration: none !important;
    @include mq(sp) {
      @include fz_vw(15);
      line-height: 31px;
    }
    &:hover {
      @include mq(pc) {
        background: #fff;
        color: $black;
        font-weight: bold;
        text-decoration: none;
      }
    }
    &.prev-link,
    &.next-link {
      background: #ddd;
    }
    &:hover.prev-link,
    &:hover.next-link {
      background: #eee;
    }
  }
}
