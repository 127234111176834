@charset "UTF-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;*/
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  color: #2C3031;
  -webkit-text-size-adjust: none;
  /* -webkit-font-smoothing: antialiased; */
  line-height: 1.8;
  overflow-x: hidden;
}

@media screen and (max-width: 749px) {
  body {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

a {
  color: #2C3031;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  transition: .2s;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

@media screen and (min-width: 960px) {
  a:hover img {
    opacity: 0.9;
  }
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clear {
  clear: both;
}

@media screen and (min-width: 960px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 749px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 749px) {
  .sp-s {
    display: none !important;
  }
}

@media screen and (max-width: 749px) {
  .sp-s {
    display: block !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 749px) {
  .pc {
    display: block !important;
  }
}

@media screen and (max-width: 749px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 749px) {
  .pc-inline {
    display: inline !important;
  }
}

@media screen and (max-width: 749px) {
  .pc-inline {
    display: none !important;
  }
}

@media screen and (max-width: 959px) and (min-width: 749px) {
  .pc-s {
    display: block !important;
  }
}

@media screen and (max-width: 749px) {
  .pc-s {
    display: none !important;
  }
}

/* @group float
-------------------------------- */
.right {
  float: right;
}

.left {
  float: left;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @text-align
-------------------------------- */
.ta-right {
  text-align: right !important;
}

.ta-center {
  text-align: center !important;
}

.ta-left {
  text-align: left !important;
}

/* @vertical-align
-------------------------------- */
.va-top {
  vertical-align: top !important;
}

.va-bottom {
  vertical-align: bottom !important;
}

.va-middle {
  vertical-align: middle !important;
}

/* @group color
-------------------------------- */
.red {
  color: #EA5413 !important;
}

.blue {
  color: #0099ff !important;
}

.gray {
  color: #666 !important;
}

.pink {
  color: #C60070 !important;
}

.peach {
  color: #F86363 !important;
}

.green {
  color: #89B929 !important;
}

/* @group margin
-------------------------------- */
.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml40 {
  margin-left: 40px !important;
}

/* @font size
-------------------------------- */
.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs21 {
  font-size: 21px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs23 {
  font-size: 23px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs25 {
  font-size: 25px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs27 {
  font-size: 27px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs29 {
  font-size: 29px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs31 {
  font-size: 31px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs34 {
  font-size: 34px !important;
}

.fs36 {
  font-size: 36px !important;
}

.fs38 {
  font-size: 38px !important;
}

.fs48 {
  font-size: 48px !important;
}

.fs56 {
  font-size: 56px !important;
}

.fs64 {
  font-size: 64px !important;
}

/* @font-weight
-------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

/* @border
-------------------------------- */
.bb-none {
  border-bottom: 0 !important;
}

.bl-none {
  border-left: 0 !important;
}

.br-none {
  border-right: 0 !important;
}

.bt-none {
  border-top: 0 !important;
}

/* @status font color
-------------------------------- */
.required {
  color: #c00;
}

.error-message {
  color: #c00;
}

/* @wp image
-------------------------------- */
.alignleft {
  float: left;
  margin: 0 20px 10px 0;
}

.alignright {
  float: right;
  margin: 0 0 10px 20px;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* @flex
-------------------------------- */
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 749px) {
  .flex {
    display: block;
    width: auto;
  }
}

.flex-sp {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* @font-family
-------------------------------- */
.mincho {
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.yu {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.wrapper {
  overflow: hidden;
}

.main:before, .main:after {
  content: "";
  display: table;
}

.main:after {
  clear: both;
}

.main--page {
  margin-bottom: 60px;
}

.base-wrap {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0;
}

@media screen and (max-width: 749px) {
  .base-wrap {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.base-wrap--space {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 749px) {
  .base-wrap--space {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.screen-reader-text {
  display: none;
}

.page {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.page:before, .page:after {
  content: "";
  display: table;
}

.page:after {
  clear: both;
}

@media screen and (max-width: 749px) {
  .page {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

.page .contents {
  float: left;
  width: 600px;
}

.page .contents:before, .page .contents:after {
  content: "";
  display: table;
}

.page .contents:after {
  clear: both;
}

@media screen and (max-width: 749px) {
  .page .contents {
    float: none;
    width: auto;
  }
}

.page .side {
  float: right;
  width: 300px;
}

@media screen and (max-width: 749px) {
  .page .side {
    float: none;
    width: auto;
  }
}

.page--1col {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
}

.page--1col:before, .page--1col:after {
  content: "";
  display: table;
}

.page--1col:after {
  clear: both;
}

@media screen and (max-width: 749px) {
  .page--1col {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

.page--1col .contents:before, .page--1col .contents:after {
  content: "";
  display: table;
}

.page--1col .contents:after {
  clear: both;
}

.social-links {
  margin-bottom: 50px;
}

/* ヘッダー */
.header {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 10px;
}

@media screen and (max-width: 749px) {
  .header {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.header__logo {
  margin: 5px 0;
}

@media screen and (max-width: 749px) {
  .header__logo {
    float: none;
  }
}

.header__logo span {
  margin-left: 15px;
  line-height: 43px;
  color: #666;
}

.header__cart {
  position: absolute;
  right: 0;
  top: 15px;
}

@media screen and (max-width: 749px) {
  .header__cart {
    display: none;
  }
}

.header__cart a {
  display: inline-block;
  padding: 5px 18px 5px 50px;
  border: 2px solid #8D5D67;
  background: url(/theme/wayouen-media/img/common/icon/icn_cart.svg) -8px 3px no-repeat;
  background-size: 30px 30px;
  color: #A0707A;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .header__cart a {
    background-position: 6px 3px;
    background-size: 24px 24px;
  }
}

@media screen and (min-width: 960px) {
  .header__cart a:hover {
    background-position: 8px 3px;
  }
}

/* グローバルナビゲーション */
.gnavi {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media screen and (max-width: 749px) {
  .gnavi {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 749px) {
  .gnavi {
    padding: 10px 0 40px;
    background: #A0707A;
  }
}

.gnavi__wrap {
  background: #A0707A;
}

@media screen and (max-width: 749px) {
  .gnavi__wrap {
    position: absolute;
    top: 73px;
    left: 0;
    z-index: 10;
    display: none;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
  }
}

.gnavi > ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 749px) {
  .gnavi > ul {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 749px) {
  .gnavi > ul {
    margin-bottom: 30px;
  }
}

.gnavi > ul > li {
  width: 20%;
}

@media screen and (max-width: 749px) {
  .gnavi > ul > li {
    width: auto;
  }
}

.gnavi > ul > li a {
  display: block;
  color: #fff;
  font-size: 1.4rem;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 50px;
}

@media screen and (max-width: 749px) {
  .gnavi > ul > li a {
    font-size: 17px;
    font-size: 4.53333vw;
    width: auto;
    padding: 5px 30px;
  }
}

.gnavi > ul > li a:hover {
  background: #8D5D67;
  text-decoration: none;
}

.gnavi > ul > li ul.dropdown-list {
  position: absolute;
  display: none;
  width: 20%;
}

@media screen and (max-width: 749px) {
  .gnavi > ul > li ul.dropdown-list {
    position: static;
    display: block;
    width: 100%;
  }
}

.gnavi > ul > li ul.dropdown-list li {
  background: #2C3031;
}

.gnavi > ul > li ul.dropdown-list li a {
  display: block;
  padding: 5px 0;
  background: #2C3031;
  color: #fff;
  text-align: center;
  line-height: 1.5;
}

.gnavi > ul > li ul.dropdown-list li a:hover {
  color: #A0707A;
}

.gnavi__cart {
  display: none;
}

@media screen and (max-width: 749px) {
  .gnavi__cart {
    display: block;
    text-align: center;
  }
}

.gnavi__cart a {
  display: inline-block;
  padding: 10px 18px 10px 50px;
  border: 2px solid #fff;
  background: url(/theme/wayouen-media/img/common/icon/icn_cart_w.svg) 10px 9px no-repeat;
  background-size: 24px 24px;
  color: #fff !important;
  font-size: 1.4rem;
}

@media screen and (max-width: 749px) {
  .gnavi__cart a {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.gnavi > ul > li:nth-child(1) a {
  padding: 12px 0;
}

.gnavi > ul > li:nth-child(1) a img {
  width: 24px;
  height: 26px;
  vertical-align: middle;
}

.gnavi__wrap.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}

.pagetop {
  position: fixed;
  bottom: 180px;
  left: 50%;
  margin-left: 510px;
  cursor: pointer;
  z-index: 10000;
}

@media screen and (max-width: 749px) {
  .pagetop {
    position: fixed;
    bottom: 20px;
    right: 10px;
    left: auto;
    margin-left: 0;
  }
}

.pagetop img {
  max-width: none;
}

.footer {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  padding: 50px 0;
  color: #2C3031;
  font-size: 1.4rem;
}

@media screen and (max-width: 749px) {
  .footer {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 749px) {
  .footer {
    display: block;
    width: auto;
  }
}

.footer__wrap {
  background: #F2F2F2;
}

.footer__info p {
  font-size: 1.3rem;
  line-height: 1.5;
}

.footer__cart {
  margin-bottom: 20px;
}

@media screen and (max-width: 749px) {
  .footer__cart {
    text-align: center;
  }
}

.footer__cart a {
  display: inline-block;
  padding: 8px 18px 8px 50px;
  border: 2px solid #8D5D67;
  background: url(/theme/wayouen-media/img/common/icon/icn_cart.svg) -8px 6px no-repeat;
  background-size: 30px 30px;
  color: #A0707A;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .footer__cart a {
    background-position: 10px 6px;
    background-size: 24px 24px;
  }
}

@media screen and (min-width: 960px) {
  .footer__cart a:hover {
    background-position: 8px 6px;
  }
}

.fnavi {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

@media screen and (max-width: 749px) {
  .fnavi {
    width: 100%;
    margin-bottom: 30px;
  }
}

.fnavi ul {
  width: 50%;
}

.fnavi ul li {
  margin-bottom: 5px;
}

.fnavi ul li a {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  color: #2C3031;
}

.fnavi ul li a::before {
  position: absolute;
  top: 10px;
  left: 0;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #A0707A;
}

.fnavi ul li a::after {
  display: block;
  content: "";
  width: 0;
  border-bottom: 1px solid #A0707A;
  transition: .2s;
}

@media screen and (min-width: 960px) {
  .fnavi ul li a:hover::after {
    width: 100%;
  }
}

.sns-links {
  margin-bottom: 20px;
}

.sns-links ul:before, .sns-links ul:after {
  content: "";
  display: table;
}

.sns-links ul:after {
  clear: both;
}

@media screen and (max-width: 749px) {
  .sns-links ul {
    text-align: center;
  }
}

.sns-links li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.sns-links li img {
  width: 30px;
  height: 30px;
}

.sns-links li a {
  display: inline-block;
  vertical-align: top;
}

@media screen and (min-width: 960px) {
  .sns-links li a:hover {
    transform: translateY(-5px);
  }
}

.copyright {
  margin: 20px 0;
  color: #888;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.3rem;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .copyright {
    font-size: 11px;
    font-size: 2.93333vw;
    line-height: 1.2;
  }
}

.header__menu {
  display: none;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 44px;
}

@media screen and (max-width: 749px) {
  .header__menu {
    display: block;
  }
}

.header__menu b {
  display: inline-block;
  padding-top: 28px;
  color: #fff;
  font-size: 10px;
}

#panel-btn {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  background: #A0707A;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#panel-btn .close {
  background: transparent;
}

#panel-btn .close::before, #panel-btn .close::after {
  margin-top: 0;
}

#panel-btn .close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-top: -2px;
}

#panel-btn .close::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  margin-top: -2px;
}

#panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 3px;
  margin: -5px 0 0 -15px;
  background: #fff;
  -webkit-transition: .2s;
  transition: .2s;
}

#panel-btn-icon::before, #panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 3px;
  background: #fff;
  -webkit-transition: .3s;
  transition: .3s;
}

#panel-btn-icon::before {
  margin-top: -12px;
}

#panel-btn-icon::after {
  margin-top: 8px;
}

#BtnClose {
  display: none;
  padding: 20px 0 20px;
  background: #efefef;
  color: #A0707A;
  font-weight: bold;
  letter-spacing: .1em;
  text-align: center;
  font-size: 20px;
  font-size: 5.33333vw;
}

@media screen and (max-width: 749px) {
  #BtnClose {
    display: block;
  }
}

/*--------------------------------------------------------
	サイドナビ
----------------------------------------------------------*/
.side--1col {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 0;
}

@media screen and (max-width: 749px) {
  .side--1col {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 749px) {
  .side--1col {
    margin: 30px 20px;
  }
}

.side--1col .widget-area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 749px) {
  .side--1col .widget-area {
    display: block;
    width: auto;
  }
}

.side--1col .widget-area .widget {
  width: 30%;
}

@media screen and (max-width: 749px) {
  .side--1col .widget-area .widget {
    width: auto;
  }
}

.side--1col .side-fb {
  margin: 0 auto;
  width: 500px;
}

@media screen and (max-width: 749px) {
  .side--1col .side-fb {
    width: auto;
  }
}

.side--1col .fb-container {
  width: 100%;
}

/* サイドバー */
.blog-calendar th,
.blog-calendar td {
  padding: 6px 0;
}

.blog-calendar tr:first-child td {
  background: #2C3031;
  color: #fff;
}

/* サイドバナー */
.salon-banner {
  width: 1020px;
  margin: 30px auto;
}

.salon-banner img {
  max-width: 100%;
}

/* ローカルナビ */
.widget {
  margin-bottom: 50px;
}

.widget-local-navi > ul {
  margin: 0 -2px;
  padding: 0 2px 15px;
}

.widget-local-navi > ul > li {
  border-bottom: 1px dotted #CCCCCC;
}

.widget-local-navi li a {
  display: block;
  padding: 16px 10px 16px 16px;
  text-decoration: none;
  color: #333;
}

.widget-local-navi li a:hover {
  color: #CC3300;
}

.widget-local-navi li:last-child {
  border-bottom: none;
}

/* ローカルナビ2層 */
.widget-local-navi ul ul {
  margin-left: 16px;
  margin-bottom: 10px;
}

.widget-local-navi ul ul li a {
  padding: 0px 10px 5px 20px;
  font-size: 13px;
}

.blog-widget li {
  padding: 10px 0;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
}

.blog-widget li a {
  color: #1D4C86;
  text-decoration: none;
}

.blog-widget li a:hover {
  color: #00A0D9;
  text-decoration: underline;
}

.widget {
  margin-bottom: 30px;
}

.widget img {
  max-width: 100%;
}

.widget h2 {
  margin-bottom: 10px;
  padding: 10px 15px;
  background: #2C3031;
  color: #fff;
  font-size: 15px;
}

.widget li {
  margin-left: 5px;
  margin-bottom: 10px;
}

.widget li a {
  position: relative;
  display: block;
  padding: 4px 0 4px 15px;
  color: #333333;
  text-decoration: none;
}

.widget li a::before {
  position: absolute;
  top: 9px;
  left: 0;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background: #8D5D67;
  border-radius: 50%;
}

/* ブログ side calendar */
.widget-blog-calendar table {
  border-collapse: collapse;
  box-sizing: border-box;
}

.widget-blog-calendar th {
  border: 1px solid #ededed;
}

.widget-blog-calendar td {
  position: relative;
  text-align: center;
  border: 1px solid #ededed;
}

.widget-blog-calendar td center {
  padding: 6px 10px;
}

.widget-blog-calendar td center a {
  color: #fff;
}

.widget-blog-calendar td > a {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 6px 0;
  background: #A0707A;
  color: #fff;
  text-align: center;
  text-decoration: underline;
}

.button--01 {
  text-align: center;
}

.button--01 a {
  position: relative;
  display: inline-block;
  padding: 6px 50px;
  background: #8D5D67;
  color: #fff !important;
  overflow: hidden;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .button--01 a {
    width: 100%;
    padding: 10px 0;
  }
}

.button--01 a span {
  z-index: 20;
}

.button--01 a::after {
  position: absolute;
  right: 12px;
  top: 50%;
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-top: -6px;
  border-style: solid;
  border-width: 5px 0 5px 7px;
  border-color: transparent transparent transparent #fff;
}

.button--01 a::before {
  position: absolute;
  top: -50px;
  left: -75px;
  content: "";
  display: block;
  width: 50px;
  height: 155px;
  background: #fff;
  opacity: .2;
  transform: rotate(35deg);
  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 10;
}

@media screen and (min-width: 960px) {
  .button--01 a:hover::before {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.button-2col {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 749px) {
  .button-2col {
    display: block;
    width: auto;
  }
}

.shiny {
  overflow: hidden;
}

.shiny::before {
  display: block;
  position: absolute;
  z-index: -1;
  left: -30%;
  top: -50%;
  content: "";
  width: 80px;
  height: 200px;
  transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  background-image: linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 50%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, white), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff white, rgba(255, 255, 255, 0) 100%);
  animation: shiny 10s infinite linear;
  -webkit-animation: shiny 10s infinite linear;
  -moz-animation: shiny 10s infinite linear;
  opacity: .4;
}

@keyframes shiny {
  0% {
    left: -30%;
  }
  5% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

@-webkit-keyframes shiny {
  0% {
    left: -30%;
  }
  5% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

@-moz-keyframes shiny {
  0% {
    left: -30%;
  }
  5% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

.title-main--01 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: normal;
}

@media screen and (max-width: 749px) {
  .title-main--01 {
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.title-main--02 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .title-main--02 {
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.title-main--03 {
  margin-bottom: 20px;
  font-size: 36px;
}

@media screen and (max-width: 749px) {
  .title-main--03 {
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.title-sub__wrap {
  margin-top: 50px;
  border-bottom: 3px solid #A0707A;
}

@media screen and (max-width: 749px) {
  .title-sub__wrap {
    margin-top: 0;
  }
}

.title-simple--01 {
  margin-bottom: 20px;
  font-size: 3rem;
}

@media screen and (max-width: 749px) {
  .title-simple--01 {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 15px;
  }
}

.title-simple--02 {
  margin-top: 40px;
  margin-bottom: 20px;
  color: #A0707A;
  font-size: 2.4rem;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .title-simple--02 {
    font-size: 18px;
    font-size: 4.8vw;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.title-simple--03 {
  margin-top: 20px;
  margin-bottom: 0;
  color: #A0707A;
  font-size: 2.0rem;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .title-simple--03 {
    font-size: 16px;
    font-size: 4.26667vw;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

.table-title--01 {
  padding: 4px;
  background: #2C3031;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .table-title--01 {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.breadcrumbs {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 1.4rem;
}

@media screen and (max-width: 749px) {
  .breadcrumbs {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 749px) {
  .breadcrumbs {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.breadcrumbs__list:before, .breadcrumbs__list:after {
  content: "";
  display: table;
}

.breadcrumbs__list:after {
  clear: both;
}

.breadcrumbs__list li {
  display: inline-block;
}

.breadcrumbs__list li:not(:first-child)::before {
  padding-left: 6px;
  padding-right: 6px;
  content: "＞";
}

/* ブログ　pagination */
.pagination {
  margin: 40px 0 80px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  text-align: center;
}

.pagination span.current,
.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a,
.pagination span.number a {
  margin: 0 2px;
  padding: 10px 16px;
  border: 1px solid #ddd;
  text-decoration: none !important;
}

@media screen and (max-width: 749px) {
  .pagination span.current,
  .pagination span.disabled,
  .pagination span.first a,
  .pagination span.last a,
  .pagination span.prev a,
  .pagination span.next a,
  .pagination span.number a {
    margin: 0;
    font-size: 15px;
    line-height: 11px;
  }
}

.pagination span:first-child a {
  border-left: 1px solid #ddd;
}

.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a {
  padding: 10px;
}

.pagination span.current,
.pagination span a:hover {
  background: #A0707A;
  color: #fff;
}

.pagination a:active {
  background: #A0707A;
}

.pagination span.first a,
.pagination span.last a {
  position: relative;
}

.pagination span.first a::before,
.pagination span.last a::after {
  position: absolute;
  top: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 14px;
  margin-top: -6px;
  background: #000;
}

@media screen and (max-width: 749px) {
  .pagination span.first a::before,
  .pagination span.last a::after {
    height: 11px;
    margin-top: -5px;
  }
}

.pagination span.first a::before {
  left: 13px;
}

@media screen and (max-width: 749px) {
  .pagination span.first a::before {
    left: 12px;
  }
}

.pagination span.last a::after {
  right: 13px;
}

@media screen and (max-width: 749px) {
  .pagination span.last a::after {
    right: 12px;
  }
}

/*--------------------------------------------------------
  コンテンツナビ
----------------------------------------------------------*/
.contents-navi {
  margin-top: 40px;
  margin-bottom: 120px;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .contents-navi {
    margin-top: 20px;
    margin-bottom: 80px;
  }
}

.contents-navi a {
  display: inline-block;
  width: 30%;
  border: 1px solid #ddd;
  line-height: 50px;
  text-align: center;
  text-decoration: none !important;
}

@media screen and (max-width: 749px) {
  .contents-navi a {
    font-size: 15px;
    font-size: 4vw;
    line-height: 31px;
  }
}

@media screen and (min-width: 960px) {
  .contents-navi a:hover {
    background: #fff;
    color: #2C3031;
    font-weight: bold;
    text-decoration: none;
  }
}

.contents-navi a.prev-link, .contents-navi a.next-link {
  background: #ddd;
}

.contents-navi a:hover.prev-link, .contents-navi a:hover.next-link {
  background: #eee;
}

@media screen and (max-width: 749px) {
  .table--01 {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.table--01__title {
  background: #3E3A39;
  color: #fff;
}

.table--01__subtitle {
  background: #F2F2F2;
  color: #2C3031 !important;
  text-align: center;
}

.table--01 tr {
  border-bottom: 1px solid #B9B9B9;
}

.table--01 tr:nth-child(2) th {
  padding: 15px 0;
}

.table--01 th:not(:last-child) {
  border-right: 1px solid #B9B9B9;
}

.table--01 td:not(:last-child) {
  border-right: 1px solid #B9B9B9;
}

.table--01 th, .table--01 td {
  width: 25%;
  padding: 7px 0;
  text-align: center;
}

.table--01 td:nth-child(1) {
  color: #A0707A;
}

.table--01 th:nth-child(2) {
  background: #A0707A;
  color: #fff;
}

.table--01 td:nth-child(2) {
  background: #A0707A;
  color: #fff;
}

.table--02 {
  border-bottom: 2px solid #B9B9B9;
}

.table--02 tr th {
  padding: 4px;
  background: #A0707A;
  color: #fff;
  font-size: 2.0rem;
  font-weight: normal;
}

@media screen and (max-width: 749px) {
  .table--02 tr th {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.table--02 tr td {
  padding: 6px;
  border-bottom: 1px solid #B9B9B9;
  background: #F2F2F2;
  color: #A0707A;
  text-align: center;
}

.table--02 tr td:nth-child(1) {
  border-right: 1px solid #B9B9B9;
}

@media screen and (max-width: 749px) {
  .table-sp table {
    display: table !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 749px) {
  .table-sp table tbody {
    display: table-row-group !important;
  }
}

@media screen and (max-width: 749px) {
  .table-sp table tr {
    display: table-row !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 749px) {
  .table-sp table tr th {
    display: table-cell !important;
  }
}

@media screen and (max-width: 749px) {
  .table-sp table tr td {
    display: table-cell !important;
  }
}

.icon-handle {
  display: inline-block;
  padding-left: 24px;
  background: url(/img/common/icn_about_w.svg) left center no-repeat;
  background-size: 18px 18px;
}

.txt-pop {
  position: relative;
  width: 80%;
  margin: 0 auto 30px;
  padding: 24px 0 30px;
  text-align: center;
  border: solid 2px #b5b5b6;
  border-radius: 10px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 6px 7px 0 #b5b5b6;
  box-shadow: 6px 7px 0 #b5b5b6;
}

@media screen and (max-width: 749px) {
  .txt-pop {
    padding: 18px 0 20px;
  }
}

.txt-pop::before, .txt-pop::after {
  position: absolute;
  display: block;
  content: '';
  border: solid transparent;
}

.txt-pop::before {
  z-index: 3;
  right: 45%;
  bottom: -37px;
  border-width: 20px 15px;
  border-top-color: #FFFFFF;
}

.txt-pop::after {
  z-index: 2;
  right: 43.7%;
  bottom: -45px;
  border-width: 20px 15px;
  border-top-color: #b5b5b6;
}

.banner-1col {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .banner-1col {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 749px) {
  .banner-1col {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.banner-1col a {
  display: inline-block;
  margin-bottom: 20px;
}

@media screen and (max-width: 749px) {
  .banner-1col a {
    margin-bottom: 10px;
  }
}

.banner-2col {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 749px) {
  .banner-2col {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 749px) {
  .banner-2col {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 749px) {
  .banner-2col {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.banner-2col a {
  display: block;
  width: 49%;
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .banner-2col a {
    width: auto;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 960px) {
  .banner-2col a:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 749px) {
  .banner-2col a:last-child {
    margin-bottom: 0;
  }
}

.banner-2col-2sp {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 749px) {
  .banner-2col-2sp {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 749px) {
  .banner-2col-2sp {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.banner-2col-2sp a {
  display: block;
  width: 49%;
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .banner-2col-2sp a {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 960px) {
  .banner-2col-2sp a:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 749px) {
  .banner-2col-2sp a:last-child {
    margin-bottom: 0;
  }
}

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}

.submit {
  margin: 60px 0;
  text-align: center;
}

.submit .button {
  display: inline-block;
  padding: 16px 80px;
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro","ＭＳ Ｐ明朝", "MS PMincho",  serif;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  -webkit-font-smoothing: antialiased;
  background: #A0707A;
  background: -moz-linear-gradient(top, #A0707A 0%, #8D5D67 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #A0707A), color-stop(100%, #8D5D67));
  background: -webkit-linear-gradient(top, #A0707A 0%, #8D5D67 100%);
  background: -o-linear-gradient(top, #A0707A 0%, #8D5D67 100%);
  background: -ms-linear-gradient(top, #A0707A 0%, #8D5D67 100%);
  background: linear-gradient(to bottom, #A0707A 0%, #8D5D67 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#125115', endColorstr='$sub',GradientType=0 );
  cursor: pointer;
}

@media screen and (max-width: 749px) {
  .submit .button {
    width: 90%;
    margin-bottom: 10px;
    padding: 16px 0;
  }
}

.auth-captcha {
  margin: 30px 0;
  text-align: center;
}

@media screen and (max-width: 749px) {
  .form-table {
    border: 0 !important;
  }
}

.form-table tr th {
  width: 300px;
  padding-left: 30px;
  text-align: left;
}

@media screen and (max-width: 749px) {
  .form-table tr th {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 749px) {
  .form-table tr td {
    display: block;
    width: 100%;
    padding: 10px 0 30px;
  }
}

.form-table input[type=text] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 90%;
  margin: 0 10px 4px 0;
  padding: 8px;
  border: 1px solid #A0707A;
  font-size: 16px;
}

@media screen and (max-width: 749px) {
  .form-table input[type=text] {
    width: 100%;
  }
}

.form-table .checkbox {
  font-size: 16px;
}

.form-table textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border: 1px solid #A0707A;
  font-size: 16px;
}

.form-table select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 4px 0;
  border: 1px solid #A0707A;
  font-size: 16px;
}

.form-table a {
  color: #A0707A;
  text-decoration: underline;
}

.form-table ul li {
  padding-left: 0;
  background: none !important;
}

.form-table ul li::before {
  display: none;
}

.form-table .form-s {
  width: 20% !important;
}

@media screen and (max-width: 749px) {
  .form-table .form-s {
    width: 27% !important;
  }
}

.form-table .form-m {
  width: 36% !important;
}

@media screen and (max-width: 749px) {
  .form-table .form-m {
    width: 47% !important;
  }
}

.form-table .form-l {
  width: 90%;
}

@media screen and (max-width: 749px) {
  .form-table .form-l {
    width: 100%;
  }
}

.required {
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 5px;
  padding: 1px 4px;
  border-radius: 4px;
  background: #c00;
  color: #fff;
  font-size: 12px;
}

.mail-description {
  margin-bottom: 10px;
}

.mail-before-attachment {
  margin-right: 10px;
}

.mail-after-attachment {
  margin-right: 10px;
}

#flashMessage {
  margin-bottom: 10px;
}

.alert-message {
  color: #c00;
}

form ul {
  margin: 0 !important;
}

form ul li {
  padding-left: 0 !important;
}

form ul li::before {
  display: none !important;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?ax5cum");
  src: url("../fonts/icomoon.eot?ax5cum#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?ax5cum") format("truetype"), url("../fonts/icomoon.woff?ax5cum") format("woff"), url("../fonts/icomoon.svg?ax5cum#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-line:before {
  content: "\e900";
}

.icon-hatebu:before {
  content: "\e901";
}

.social-links ul {
  display: flex;
  flex-wrap: wrap;
}

.social-links ul li {
  width: 20%;
  padding: 0 !important;
  background: none !important;
}

.social-links ul li .sns-btn {
  display: block;
  margin: 0 1px 8px;
  max-width: 240px;
  height: 48px;
  background: #2C3031;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .social-links ul li .sns-btn b {
    display: none;
  }
}

@media screen and (max-width: 749px) {
  .social-links ul li .sns-btn i {
    font-size: 24px;
    line-height: 48px;
  }
}

.social-links ul li .s_twitter {
  background: #55acee;
}

.social-links ul li .s_facebook {
  background: #3B5998;
}

.social-links ul li .s_hatena {
  background: #008fde;
}

.social-links ul li .s_pocket {
  background: #ef4056;
}

.social-links ul li .s_line {
  background: #1dcd00;
}

.search-box {
  position: absolute;
  top: 20px;
  left: 400px;
}

@media screen and (max-width: 749px) {
  .search-box {
    display: none;
    position: static;
    margin-top: 30px;
    text-align: center;
  }
}

.search-box input[type=text] {
  width: 220px;
  padding: 6px 12px;
  border: 1px solid #A0707A;
  border-radius: 20px;
  font-size: 1.4rem;
}

@media screen and (max-width: 749px) {
  .search-box input[type=text] {
    font-size: 17px;
    font-size: 4.53333vw;
    width: 300px;
    transform: translate3d(10px, 0, 0);
  }
}

.search-box input[type=image] {
  transform: translate3d(-36px, 0, 0);
}

.gnavi .search-box {
  display: none;
}

@media screen and (max-width: 749px) {
  .gnavi .search-box {
    display: block;
  }
}

.hero {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 380px;
  margin-bottom: 50px;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .hero {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 749px) {
  .hero {
    height: auto;
  }
}

.hero__main {
  width: 70%;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .hero__main {
    width: auto;
  }
}

.hero__main img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

@media screen and (max-width: 749px) {
  .hero__main img {
    height: auto;
  }
}

.hero__sub {
  width: 30%;
}

@media screen and (max-width: 749px) {
  .hero__sub {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.hero__sub__item {
  overflow: hidden;
}

.hero__sub__item img {
  width: 100%;
  height: 190px;
  object-fit: cover;
}

@media screen and (max-width: 749px) {
  .hero__sub__item img {
    height: auto;
  }
}

.hero__mv {
  position: relative;
}

.hero__mv__meta {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.hero__mv__meta .name {
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
}

@media screen and (max-width: 749px) {
  .hero__mv__meta .name {
    display: none;
  }
}

.hero__mv__meta .category {
  display: inline-block;
  padding: 2px 4px;
  border: 1px solid #fff;
  font-size: 1.2rem;
  line-height: 1;
}

@media screen and (max-width: 749px) {
  .hero__mv__meta .category {
    display: none;
  }
}

.hero__mv__meta .date {
  display: inline-block;
  font-size: 1.4rem;
}

@media screen and (max-width: 749px) {
  .hero__mv__meta .date {
    display: none;
  }
}

.hero__mv__meta .title {
  padding-top: 5px;
  line-height: 1.2;
}

@media screen and (max-width: 749px) {
  .hero__mv__meta .title {
    font-size: 14px;
    font-size: 3.73333vw;
    padding-top: 0;
    border-color: #2C3031;
  }
}

.hero a {
  display: block;
}

.hero a img {
  transition: 1s;
}

@media screen and (min-width: 960px) {
  .hero a:hover img {
    transform: scale(1.1, 1.1);
    transition: 1s;
  }
}

.top-feed {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 50px;
}

@media screen and (max-width: 749px) {
  .top-feed {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: 749px) {
  .top-feed {
    display: block;
    width: auto;
  }
}

.top-feed__item {
  width: 47%;
  margin-bottom: 60px;
}

@media screen and (max-width: 749px) {
  .top-feed__item {
    width: 100%;
    margin-bottom: 40px;
  }
}

.top-feed__item ul {
  margin-bottom: 60px;
}

.top-feed__item ul li {
  margin-bottom: 30px;
}

@media screen and (max-width: 749px) {
  .top-feed__item ul li {
    margin-bottom: 25px;
  }
}

.top-feed__item ul li a {
  display: flex;
  justify-content: space-between;
  color: #2C3031;
}

.top-feed__item ul li .eyecatch {
  width: 30%;
  overflow: hidden;
}

.top-feed__item ul li .eyecatch img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: .2s;
}

@media screen and (max-width: 749px) {
  .top-feed__item ul li .eyecatch img {
    height: 70px;
  }
}

.top-feed__item ul li .data {
  width: 62%;
}

@media screen and (max-width: 749px) {
  .top-feed__item ul li .data {
    width: 64%;
  }
}

.top-feed__item ul li .category {
  margin-right: 10px;
  padding: 3px 6px 4px;
  background: #8D5D67;
  color: #fff;
  font-size: 1.2rem;
  vertical-align: middle;
}

@media screen and (max-width: 749px) {
  .top-feed__item ul li .category {
    font-size: 12px;
    font-size: 3.2vw;
    display: inline-block;
    height: 19px;
    padding: 0 6px;
    line-height: 19px;
    vertical-align: top;
  }
}

.top-feed__item ul li .title {
  margin: 10px 0;
  line-height: 1.6;
}

@media screen and (max-width: 749px) {
  .top-feed__item ul li .title {
    font-size: 16px;
    font-size: 4.26667vw;
    margin-top: 0;
    line-height: 1.5;
  }
}

.top-feed__item ul li .date {
  color: #777;
  vertical-align: middle;
}

@media screen and (max-width: 749px) {
  .top-feed__item ul li .date {
    display: inline-block;
    height: 19px;
    line-height: 19px;
    vertical-align: top;
  }
}

@media screen and (min-width: 960px) {
  .top-feed a:hover img {
    transform: scale(1.1, 1.1);
    transition: .2s;
  }
}

.top-news {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0 50px;
}

@media screen and (max-width: 749px) {
  .top-news {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

.top-news ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 50px;
}

@media screen and (max-width: 749px) {
  .top-news ul {
    display: block;
    width: auto;
  }
}

.top-news ul li {
  width: 31%;
}

@media screen and (max-width: 749px) {
  .top-news ul li {
    width: 100%;
    margin-bottom: 25px;
  }
}

.top-news ul li a {
  display: flex;
  justify-content: space-between;
  color: #2C3031;
}

.top-news ul li .eyecatch {
  width: 30%;
  overflow: hidden;
}

.top-news ul li .eyecatch img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: .2s;
}

@media screen and (max-width: 749px) {
  .top-news ul li .eyecatch img {
    height: 70px;
  }
}

.top-news ul li .data {
  width: 62%;
}

@media screen and (max-width: 749px) {
  .top-news ul li .data {
    width: 64%;
  }
}

.top-news ul li .title {
  margin-bottom: 10px;
  line-height: 1.2;
}

.top-news ul li .text {
  line-height: 1.5;
}

@media screen and (min-width: 960px) {
  .top-news a:hover img {
    transform: scale(1.1, 1.1);
    transition: .2s;
  }
}

.top-products {
  padding: 50px 0 80px;
  background: #A0707A;
}

@media screen and (max-width: 749px) {
  .top-products {
    padding: 30px 0 50px;
  }
}

.top-products__inner {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 749px) {
  .top-products__inner {
    width: auto;
    margin-left: 25px;
    margin-right: 25px;
  }
}

.top-products ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.top-products ul li {
  width: 24%;
}

@media screen and (max-width: 749px) {
  .top-products ul li {
    width: 49%;
    margin-bottom: 5px;
  }
}

.top-products ul li p {
  margin-top: 10px;
  color: #fff;
  line-height: 1.5;
}

/*--------------------------------------------------------
  固定ページ共通
----------------------------------------------------------*/
/* サブページ共通 */
.main--page p {
  margin-bottom: 30px;
}

@media screen and (max-width: 749px) {
  .main--page p {
    margin-bottom: 20px;
  }
}

.main--page .section {
  margin-bottom: 60px;
}

@media screen and (max-width: 749px) {
  .main--page .section {
    margin-bottom: 30px;
  }
}

.main--page .copy {
  color: #A0707A;
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 749px) {
  .main--page .copy {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

.cms-area h2 {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 10px;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.2;
  text-align: left;
}

@media screen and (max-width: 749px) {
  .cms-area h2 {
    margin-bottom: 15px;
  }
}

.cms-area h2::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #eee;
  border-left: 150px solid #2C3031;
  box-sizing: border-box;
}

.cms-area h2 a {
  text-decoration: none;
}

.cms-area h3 {
  margin: 40px 0 20px;
  padding-bottom: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
}

.cms-area h4 {
  margin: 30px 0 15px;
  font-family: "ヒラギノ角ゴ Pro W6", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 1.2;
}

.cms-area a {
  text-decoration: underline;
}

.cms-area ul > li {
  padding-left: 25px;
  background: url(../img/common/icon/icon_list.png) 10px 9px no-repeat;
}

.cms-area ol {
  margin-left: 1em;
}

.cms-area table {
  width: 100% !important;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.cms-area th {
  padding: 10px;
  background: #ededed;
  border-bottom: 1px dotted #ccc;
  text-align: center;
  vertical-align: middle;
}

.cms-area td {
  padding: 10px;
  background: #fff;
  border-bottom: 1px dotted #ccc;
  text-align: left;
}

.blog-description {
  margin-bottom: 30px;
}

.blog__main {
  float: left;
  width: 600px;
}

@media screen and (max-width: 749px) {
  .blog__main {
    float: none;
    width: auto;
  }
}

.blog__side {
  float: right;
  width: 300px;
}

@media screen and (max-width: 749px) {
  .blog__side {
    float: none;
    width: auto;
  }
}

.blog__side h2 {
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 15px;
}

.blog__side h2::after {
  display: none;
}

.blog__side ul li {
  padding-left: 0;
  background: none;
}

.blog__side--1col {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 0;
}

@media screen and (max-width: 749px) {
  .blog__side--1col {
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 749px) {
  .blog__side--1col {
    margin: 30px 20px;
  }
}

.blog__side--1col .widget-area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 749px) {
  .blog__side--1col .widget-area {
    display: block;
    width: auto;
  }
}

.blog__side--1col .widget-area .widget {
  width: 30%;
}

@media screen and (max-width: 749px) {
  .blog__side--1col .widget-area .widget {
    width: auto;
  }
}

.blog__side--1col .side-fb {
  margin: 0 auto;
  width: 500px;
}

@media screen and (max-width: 749px) {
  .blog__side--1col .side-fb {
    width: auto;
  }
}

.blog__side--1col .fb-container {
  width: 100%;
}

.blog__side--1col h2 {
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 15px;
}

.blog__side--1col h2::after {
  display: none;
}

.blog__side--1col ul li {
  padding-left: 0;
  background: none;
}

.blog .meta {
  margin-bottom: 20px;
}

.blog .category {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.blog .category a {
  display: inline-block;
  padding: 1px 10px;
  background: #8D5D67;
  color: #fff;
  font-size: 1.4rem;
  text-decoration: none;
}

@media screen and (max-width: 749px) {
  .blog .category a {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.blog .date {
  color: #777;
  vertical-align: middle;
}

.blog .tag span {
  display: inline-block;
  padding: 1px 20px;
  border: 1px solid #8D5D67;
  color: #8D5D67;
  font-size: 1.4rem;
  vertical-align: middle;
}

@media screen and (max-width: 749px) {
  .blog .tag span {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.blog-default-index .post {
  margin-bottom: 80px;
}

.blog-default-index .post:before, .blog-default-index .post:after {
  content: "";
  display: table;
}

.blog-default-index .post:after {
  clear: both;
}

.blog-default-index .post > a {
  position: relative;
  display: block;
  text-decoration: none;
}

.blog-default-index .post > a::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(88, 0, 0, 0);
  transition: 1s;
}

@media screen and (min-width: 960px) {
  .blog-default-index .post > a:hover::after {
    background: rgba(88, 0, 0, 0.1);
    transition: 1s;
  }
}

.blog-default-index .post .post-wrap:before, .blog-default-index .post .post-wrap:after {
  content: "";
  display: table;
}

.blog-default-index .post .post-wrap:after {
  clear: both;
}

.blog-default-index .post .eyecatch {
  float: left;
  width: 30%;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .blog-default-index .post .eyecatch {
    float: none;
    width: auto;
    margin-bottom: 20px;
  }
}

.blog-default-index .post .eyecatch img {
  width: 100%;
  object-fit: cover;
  transition: .2s;
}

.blog-default-index .post .data {
  float: right;
  width: 67%;
}

@media screen and (max-width: 749px) {
  .blog-default-index .post .data {
    float: none;
    width: auto;
  }
}

.blog-default-index .post .data .tag {
  margin-top: 20px;
}

.blog-default-index .post .text {
  margin-bottom: 0;
}

.blog-default-index .post .more {
  text-align: right;
}

.blog-default-single .meta {
  margin-top: 20px;
}

.blog-default-single .eyecatch {
  margin-bottom: 6px;
}

.blog-default-single .tag {
  display: block;
  margin-top: 10px;
}

.blog-grid-index {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media screen and (max-width: 749px) {
  .blog-grid-index {
    display: block;
    width: auto;
  }
}

.blog-grid-index .post {
  width: 30%;
  margin-right: 5%;
  margin-bottom: 60px;
}

.blog-grid-index .post:before, .blog-grid-index .post:after {
  content: "";
  display: table;
}

.blog-grid-index .post:after {
  clear: both;
}

@media screen and (max-width: 749px) {
  .blog-grid-index .post {
    width: auto;
    margin-right: 0;
  }
}

.blog-grid-index .post:nth-child(3n) {
  margin-right: 0;
}

.blog-grid-index .post > a {
  position: relative;
  display: block;
  height: 100%;
  text-decoration: none;
}

@media screen and (max-width: 749px) {
  .blog-grid-index .post > a {
    height: auto;
  }
}

.blog-grid-index .post > a::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(88, 0, 0, 0);
  transition: 1s;
}

@media screen and (min-width: 960px) {
  .blog-grid-index .post > a:hover::after {
    background: rgba(88, 0, 0, 0.1);
    transition: 1s;
  }
}

.blog-grid-index .post .post-wrap:before, .blog-grid-index .post .post-wrap:after {
  content: "";
  display: table;
}

.blog-grid-index .post .post-wrap:after {
  clear: both;
}

.blog-grid-index .post .eyecatch {
  margin-bottom: 10px;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .blog-grid-index .post .eyecatch {
    margin-bottom: 20px;
  }
}

.blog-grid-index .post .eyecatch img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: .2s;
}

@media screen and (max-width: 749px) {
  .blog-grid-index .post .eyecatch img {
    height: 100px;
  }
}

.blog-grid-index .post .data .tag {
  margin-top: 20px;
}

@media screen and (max-width: 749px) {
  .blog-grid-index .post .data .tag {
    margin-top: 20px;
  }
}

.blog-grid-index .post .text {
  margin-bottom: 0;
}

.blog-grid-index .post .more {
  text-align: right;
}

.blog-grid-single .eyecatch {
  float: left;
  width: 30%;
}

@media screen and (max-width: 749px) {
  .blog-grid-single .eyecatch {
    float: none;
    width: auto;
    margin-bottom: 15px;
  }
}

.blog-grid-single .data {
  float: right;
  width: 65%;
}

@media screen and (max-width: 749px) {
  .blog-grid-single .data {
    float: none;
    width: auto;
  }
}

.blog-grid-single .data .tag {
  display: block;
  margin-top: 10px;
}

.blog-grid-single .post {
  clear: both;
}

.blog-mix-index .post {
  margin-bottom: 80px;
}

.blog-mix-index .post:before, .blog-mix-index .post:after {
  content: "";
  display: table;
}

.blog-mix-index .post:after {
  clear: both;
}

.blog-mix-index .post > a {
  position: relative;
  display: block;
  text-decoration: none;
}

.blog-mix-index .post > a::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(88, 0, 0, 0);
  transition: 1s;
}

@media screen and (min-width: 960px) {
  .blog-mix-index .post > a:hover::after {
    background: rgba(88, 0, 0, 0.1);
    transition: 1s;
  }
}

.blog-mix-index .post .post-wrap:before, .blog-mix-index .post .post-wrap:after {
  content: "";
  display: table;
}

.blog-mix-index .post .post-wrap:after {
  clear: both;
}

.blog-mix-index .post .eyecatch {
  float: left;
  width: 30%;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .blog-mix-index .post .eyecatch {
    float: none;
    width: auto;
    margin-bottom: 20px;
  }
}

.blog-mix-index .post .eyecatch img {
  width: 100%;
  object-fit: cover;
  transition: .2s;
}

.blog-mix-index .post .data {
  float: right;
  width: 67%;
}

@media screen and (max-width: 749px) {
  .blog-mix-index .post .data {
    float: none;
    width: auto;
  }
}

.blog-mix-index .post .data .tag {
  margin-top: 20px;
}

.blog-mix-index .post .text {
  margin-bottom: 0;
}

.blog-mix-index .post .more {
  text-align: right;
}

.blog-mix-single .eyecatch {
  width: 800px;
  margin: 0 auto 6px;
}

@media screen and (max-width: 749px) {
  .blog-mix-single .eyecatch {
    width: auto;
  }
}

.blog-mix-single .meta {
  margin-top: 20px;
}

.blog-mix-single .meta .tag {
  margin-top: 10px;
}

.blog-2col-index {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 749px) {
  .blog-2col-index {
    display: block;
    width: auto;
  }
}

.blog-2col-index .post {
  width: 46.5%;
  margin-bottom: 80px;
}

.blog-2col-index .post:before, .blog-2col-index .post:after {
  content: "";
  display: table;
}

.blog-2col-index .post:after {
  clear: both;
}

@media screen and (max-width: 749px) {
  .blog-2col-index .post {
    width: auto;
  }
}

.blog-2col-index .post .post-wrap:before, .blog-2col-index .post .post-wrap:after {
  content: "";
  display: table;
}

.blog-2col-index .post .post-wrap:after {
  clear: both;
}

.blog-2col-index .post .eyecatch {
  float: left;
  width: 40%;
  overflow: hidden;
}

@media screen and (max-width: 749px) {
  .blog-2col-index .post .eyecatch {
    float: none;
    width: auto;
    margin-bottom: 20px;
  }
}

.blog-2col-index .post .eyecatch img {
  width: 100%;
  object-fit: cover;
  transition: .2s;
}

.blog-2col-index .post .data {
  float: right;
  width: 56%;
}

@media screen and (max-width: 749px) {
  .blog-2col-index .post .data {
    float: none;
    width: auto;
  }
}

.blog-2col-index .post .data .tag {
  margin-top: 20px;
}

.blog-2col-index .post .text {
  margin-bottom: 0;
}

.blog-2col-index .post .more {
  text-align: right;
}

.blog-2col-single .meta {
  margin-top: 20px;
}

.blog-2col-single .eyecatch {
  width: 800px;
  margin: 0 auto 6px;
}

@media screen and (max-width: 749px) {
  .blog-2col-single .eyecatch {
    width: auto;
  }
}

.blog-2col-single .tag {
  display: block;
  margin-top: 10px;
}
