@charset "utf-8";

.banner-1col {
  @include base-wrap--space();
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  @include mq(sp) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    margin-bottom: 20px;
    @include mq(sp) {
      margin-bottom: 10px;
    }
  }
}
.banner-2col {
  @include base-wrap--space();
  @include flex(wrap);
  margin-top: 40px;
  margin-bottom: 40px;
  @include mq(sp) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a {
    display: block;
    width: 49%;
    margin-bottom: 20px;
    text-align: center;
    @include mq(sp) {
      width: auto;
      margin-bottom: 10px;
    }
    &:nth-last-child(-n+2) {
      @include mq(pc) {
        margin-bottom: 0;
      }
    }
    &:last-child {
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
  }
}

.banner-2col-2sp {
  @include base-wrap--space();
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
  @include mq(sp) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a {
    display: block;
    width: 49%;
    margin-bottom: 20px;
    text-align: center;
    @include mq(sp) {
      margin-bottom: 10px;
    }
    &:nth-last-child(-n+2) {
      @include mq(pc) {
        margin-bottom: 0;
      }
    }
    &:last-child {
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
  }
}